import { createContext, useEffect, useState } from 'react';
import axios from 'src/utils/axios';
import { API_BASE_URL } from 'src/config';
 
import { Typography, Alert, Box, Button } from '@mui/material';
import { Check, Loader  } from 'react-feather';
 
export const QueueContext = createContext({
  refreshQueue: () => {}, // Default value for context
});
 
export const QueueProvider = ({ children }) => {
  const [working, setWorking] = useState(false);
  const [showReloadButton, setShowReloadButton] = useState(false);
 
  const refreshQueue = () => setWorking(true);
 
  useEffect(() => {
    let interval;
    
    if (working) {
      const fetchJobs = async () => {
        const { data: hasJobs } = await axios.get(`${API_BASE_URL}/queue`);
        
        if (!hasJobs && working) {
          setWorking(false);
          setShowReloadButton(true);
          clearInterval(interval);
        }
      }
 
      interval = setInterval(fetchJobs, 5000);
    }
 
    return () => clearInterval(interval);
  }, [working]);

  useEffect(() => {
    const fetchJobs = async () => {
      const { data: hasJobs } = await axios.get(`${API_BASE_URL}/queue`);
      
      if (hasJobs) {
        setWorking(true);
      }
    }
    
    fetchJobs();
  }, []);
 
  return (
    <QueueContext.Provider
      value={{ refreshQueue, working }}
    >
      {children}
 
      {showReloadButton && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 20,
            right: 20,
            zIndex: 9999,
          }}
        >
          <Alert
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            icon={<Check size={20} />}
            onClose={() => setShowReloadButton(false)}
          >
            <Typography variant='body1' sx={{ 
              marginRight: '4px',
              fontSize: '15px',
             }} display='inline'>
              Vinnslu lokið. Endurhlaða síðuna til að sjá nýju gildin.
            </Typography>
            <Button color="inherit" size="small" sx={{ paddingTop: 0, paddingBottom: 0 }} onClick={() => window.location.reload()}>
              ENDURHLAÐA
            </Button>
          </Alert>
        </Box>
      )}
 
      {working && (
        <Box
          sx={{
            position: 'absolute',
            bottom: 20,
            right: 20,
            zIndex: 9999,
          }}
        >
          <Alert
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
            icon={<Loader size={20} />}
          >
            <Typography variant='body1' sx={{ 
              marginRight: '4px',
              fontSize: '15px',
             }} display='inline'>
              Vinnsla í gangi...
            </Typography>
          </Alert>
        </Box>
      )}
    </QueueContext.Provider>
  );
};
